import { Button, GlobeIcon, Heading, Paragraph, Stack, useBreakpointValue } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from '../../context/auth-context'
import { ExternalCookieSettingsLink } from '../_brand-specific/external-cookie-settings-link'
import { currentBrand } from '../../config'
import { isNotNull } from '../../utils/misc'
import { Link } from '../../vendor/next'
import { AVAILABLE_LOCALES } from '../../translations/language-loader/language-utils'
import { useCurrentLocale } from '../../translations/use-current-locale'
import { LanguageSwitchDialog } from '../../translations/language-loader/language-switch-dialog'

import { TrustpilotWidget } from './trustpilot-widget'
import type { FooterContent } from './utils'
import { useFooterContent } from './utils'
import { FooterWrapper } from './footer-wrapper'

const LanguageButton = styled(Button)({
  position: 'static',
})

const Content = styled(Stack)(({ theme }) => ({
  paddingBlock: theme.spacing['24x'],
  paddingInline: theme.spacing['6x'],
  [theme.mediaQueries.mdUp]: {
    paddingBlock: theme.spacing['16x'],
    paddingInline: 0,
  },
}))

const TopRow = styled(Stack)({
  minHeight: 200,
})

const LeftColumn = styled.div({
  display: 'flex',
  flex: 1,
})

const LeftColumnContent = styled(Stack)<{ hasMoreLeftPadding: boolean }>(({ hasMoreLeftPadding, theme }) => ({
  justifyContent: 'space-between',
  [theme.mediaQueries.mdUp]: {
    ...(hasMoreLeftPadding
      ? {
          paddingLeft: 142,
        }
      : {
          paddingLeft: theme.spacing['16x'],
        }),
    alignItems: 'flex-start',
  },
}))

const BrandInfo = styled(Stack)(({ theme }) => ({
  [theme.mediaQueries.mdUp]: {
    maxWidth: 242,
  },
}))

const BrandDescription = Paragraph
const RightColumn = styled.div({ flex: 1 })
const FooterLinks = styled(Stack)(({ theme }) => ({
  ...theme.typography.body.lg,
  display: 'flex',
  flex: 1,
  gap: theme.spacing['16x'],
  [theme.mediaQueries.mdUp]: {
    gap: 0,
    rowGap: theme.spacing['12x'],
  },
}))
const LinkColumn = styled(Stack)<{ numberOfColumns: number }>(({ theme, numberOfColumns }) => ({
  flex: `calc(100%/${numberOfColumns})`,
  gap: theme.spacing['6x'],
  alignItems: 'center',
  [theme.mediaQueries.mdUp]: {
    alignItems: 'flex-start',
    paddingRight: theme.spacing['12x'],
  },
}))
const LinkList = styled(Stack)(({ theme }) => ({
  flex: 1,
  gap: theme.spacing['5x'],
  whiteSpace: 'nowrap',
  alignItems: 'center',
  [theme.mediaQueries.mdUp]: {
    gap: theme.spacing['2x'],
    alignItems: 'flex-start',
  },
}))
const LicenseParagraph = styled(Paragraph)(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.text.onBrandSecondary,
  [theme.mediaQueries.mdUp]: {
    paddingLeft: 142,
    textAlign: 'left',
  },
}))

function FooterInternals({ logo: Logo, descriptionI18nKey, linkGroups }: FooterContent) {
  const { t } = useTranslation('footer')
  const isMdOrAbove = useBreakpointValue({ base: false, md: true }, { ssr: true })
  const locale = useCurrentLocale()
  const currentLanguage = AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)

  const LanguageDialogTrigger = (
    <LanguageButton variant="tertiary">
      <Stack direction="row" alignItems="center" gap={'2x'}>
        <GlobeIcon size={16} />
        {currentLanguage?.nativeName}
      </Stack>
    </LanguageButton>
  )

  return (
    <FooterWrapper>
      <Content gap="8x">
        <TopRow
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'stretch' }}
          gap={{ base: '16x', md: '0x' }}
        >
          <LeftColumn>
            <LeftColumnContent hasMoreLeftPadding={currentBrand !== 'blocket'}>
              <BrandInfo gap="6x">
                <Stack gap="2x" alignItems={{ base: 'center', md: 'flex-start' }}>
                  <Logo />
                  {descriptionI18nKey && (
                    <BrandDescription size={'sm'} textAlign={isMdOrAbove ? 'left' : 'center'}>
                      {t(descriptionI18nKey)}
                    </BrandDescription>
                  )}
                </Stack>
                <TrustpilotWidget variant={'microStar'} />
              </BrandInfo>
              {isMdOrAbove && <LanguageSwitchDialog trigger={LanguageDialogTrigger} />}
            </LeftColumnContent>
          </LeftColumn>
          <RightColumn>
            <FooterLinks
              direction={{ base: 'column', md: 'row' }}
              justifyContent={{ base: 'center', md: 'flex-start' }}
              alignItems={{ base: 'center', md: 'flex-start' }}
              wrap="wrap"
            >
              {linkGroups.map((section, index) => (
                <LinkColumn key={index} numberOfColumns={linkGroups.length}>
                  {section.titleI18nKey && (
                    <Heading as="h5" size="xs">
                      {t(section.titleI18nKey)}
                    </Heading>
                  )}
                  <LinkList>
                    {section.links.filter(isNotNull).map(({ nameI18nKey, href, isExternal }) => {
                      if (isExternal)
                        return (
                          <a key={nameI18nKey} href={href} rel="noopener noreferrer" target="_blank">
                            {t(nameI18nKey)}
                          </a>
                        )
                      return (
                        <Link key={nameI18nKey} href={href}>
                          {t(nameI18nKey)}
                        </Link>
                      )
                    })}
                  </LinkList>
                </LinkColumn>
              ))}
            </FooterLinks>
            <ExternalCookieSettingsLink />
          </RightColumn>
          {!isMdOrAbove && <LanguageSwitchDialog trigger={LanguageDialogTrigger} />}
        </TopRow>
        {currentBrand === 'qasa_france' && <LicenseParagraph>{t('license_france')}</LicenseParagraph>}
      </Content>
    </FooterWrapper>
  )
}

export function Footer() {
  const { isAuthenticated } = useAuthContext()
  const footerContent = useFooterContent(isAuthenticated)
  return <FooterInternals {...footerContent} />
}
